import { Middleware } from 'redux';

import { AuthAction } from 'modules/auth/constants';
import { AuthActionType } from 'modules/auth/types';
import { dispatchAbort, Dispatch, RootState } from 'modules/redux';
import { ConfigAction } from './constants';
import { ConfigActionType } from './types';
import { configGetAction, configLogoutAction } from './actions';
import { localStorageSetObject, localStorageRemoveItem } from 'modules/storage';

const createConfigMiddleware: () => Middleware<RootState> =
  () =>
  ({ dispatch, getState }: { dispatch: Dispatch; getState: () => RootState }) =>
  next =>
  async (action: AuthActionType | ConfigActionType) => {
    let nextAction;

    switch (action.type) {
      case AuthAction.CONFIG_SUCCESS:
      case AuthAction.LOGIN_SUCCESS:
        nextAction = next(action);
        dispatch(configGetAction());
        break;

      case ConfigAction.GET_FAILURE:
        nextAction = next(action);
        setTimeout(() => dispatch(configGetAction()), 5000);
        break;

      case ConfigAction.GET_SUCCESS:
        localStorageSetObject('CONFIG', action.data);
        break;

      case AuthAction.LOGOUT:
        localStorageRemoveItem('CONFIG');
        dispatchAbort(getState().config);
        nextAction = next(action);
        dispatch(configLogoutAction());
        break;
    }

    return nextAction ? nextAction : next(action);
  };

export default createConfigMiddleware;
